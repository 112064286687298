<template>
  <div class="form-elements">
    <vuestic-widget >
      <div class="row">
        <div class="col-md-3">
          <button
            type="button"
            v-on:click="back"
            class="btn btn-primary btn-sm"
          >{{'view.typecredit.back' | translate}}</button>
        </div>
        <div class="col-md-3 offset-md-6">
          <button
            type="button"
            v-on:click="trashZoneevent"
            class="btn btn-warning btn-sm"
          >Delete Element</button>
        </div>
      </div>
    </vuestic-widget>
    <div class="row">
      <div class="col-md-12">
        <vuestic-widget :headerText="'Actualizar Zona de Evento'">
          <form @submit.prevent="editZoneevent">
            <div class="row">
              <div class="col-md-12">
                <fieldset>
                  <div class="row">
                    <div class="col-md-6">
                      <div
                        class="form-group with-icon-right"
                        :class="{'has-error': errors.has('name'), 'valid': isSuccessfulNameValid}"
                      >
                        <div class="input-group">
                          <input id="name" name="name" v-model="name" v-validate="'required'">
                          <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                          <i class="fa fa-check valid-icon icon-right input-icon"></i>
                          <label
                            class="control-label"
                            for="name"
                          >{{'view.team.fields.name' | translate}}</label>
                          <i class="bar"></i>
                          <small
                            v-show="errors.has('name')"
                            class="help text-danger"
                          >{{ errors.first('name') }}</small>
                        </div>
                      </div>
                        <div
                        class="form-group with-icon-right"
                        :class="{'has-error': errors.has('description'), 'valid': isSuccessfulDescriptionValid}"
                      >
                        <div class="input-group">
                          <input
                            id="description"
                            name="description"
                            v-model="description"
                            v-validate="'required'"
                          >
                          <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                          <i class="fa fa-check valid-icon icon-right input-icon"></i>
                          <label
                            class="control-label"
                            for="description"
                          >{{'view.team.fields.description' | translate}}</label>
                          <i class="bar"></i>
                          <small
                            v-show="errors.has('description')"
                            class="help text-danger"
                          >{{ errors.first('description') }}</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 with-icon-right">

                      <h3>Estaciones asociadas a esta Zona de Evento</h3>
                       <div class="row">
                        <div class="col-md-6">

                      <multiselect
                        v-model="station"
                        label="name"
                        placeholder="Selecciona un elemento"
                        :options="stations"
                        :searchable="true"
                        :allow-empty="false"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          Estación:
                          <label style="font-weight:600">{{ option.name }}</label>
                        </template>
                      </multiselect>
                        </div>
                        <div class="col-md-6">
                            <button
                              type="button"
                              v-on:click="addStation"
                              class="btn btn-warning btn-sm"
                            >Agregar</button>
                        </div>
                      </div>

                      <br>
                      <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">Estación</th>
                              <th scope="col">Accion</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="stat in stationsbyzoneevent">

                              <td>{{stat.station.name}}</td>
                              <td><button
                              type="button"
                              v-on:click="deleteStation(stat.id)"
                              class="btn btn-warning btn-micro"
                            >Eliminar</button></td>

                            </tr>

                          </tbody>
                        </table>
                    </div>
                  </div>
                </fieldset>
                <div class="row">
                  <div class="col-md-4">
                     <label
                      class="control-label"
                      for="location"
                    >{{'view.typecredit.fields.status' | translate}}</label>

                    <div
                      class="form-group with-icon-right"
                      :class="{'has-error': errors.has('active')}"
                    >
                      <div class="input-group">
                        <vuestic-switch v-model="active">
                          <span slot="trueTitle">{{'view.event.fields.enabled' | translate}}</span>
                          <span slot="falseTitle">{{'view.event.fields.disabled' | translate}}</span>
                        </vuestic-switch>
                        <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                        <i class="fa fa-check valid-icon icon-right input-icon"></i>

                        <small
                          v-show="errors.has('active')"
                          class="help text-danger"
                        >{{ errors.first('active') }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-8">

                  </div>
                </div>
                <div class="col-md-3 offset-md-12">
                  <button class="btn btn-primary btn-sm" type="submit">{{$t('view.team.update')}}</button>
                </div>
              </div>
            </div>
          </form>
        </vuestic-widget>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import { SpringSpinner } from "epic-spinners";
export default {
  name: "form-elements",
  components: {
    SpringSpinner,
    Multiselect
  },
  data() {
    return {
      name: "",
      description: "",
      active: 1,
      stations:[],
      station:'',
      stationsbyzoneevent:[]
    };
  },
  mounted() {
    var id = this.$route.params.id;
    if (id !== null) {
      this.loadZoneevent(id)
        .then(data => {
          this.name = data.name;
          this.description = data.description;
          this.active = data.active;
        })
        .catch(data => {
          this.addToastMessage({
            text: "Ocurrio un error",
            type: "success"
          });
        });
    } else {
      this.addToastMessage({
        text: "Ocurrio un error",
        type: "success"
      });
    }

     this.loadStationsByEvent({id:this.idevent})
      .then(data => {
        this.stations = data;
      })
      .catch(data => {
        this.addToastMessage({
          text: "Error consultando usuarios",
          type: "warning"
        });
      });

      var idzoneevent = this.$route.params.id;
        this.loadStationsByZoneEvent(idzoneevent)
      .then(data => {
        this.stationsbyzoneevent = data;
      })
      .catch(data => {
        this.addToastMessage({
          text: "Error consultando usuarios",
          type: "warning"
        });
      });
  },
  methods: {
    ...mapActions([
      "loadZoneevent",
      "updateZoneevent",
      "deleteZoneevent",
      "loadStationsByEvent",
      "loadStationsByZoneEvent",
      "addToastMessage",
      "storeZonestation",
      "deleteZonestation"
    ]),
    back() {
      this.$router.go(-1);
    },
    editZoneevent() {
      this.$validator.validate().then(result => {
        if (result) {
          var id = this.$route.params.id;
          this.updateZoneevent({
            name: this.name,
            description: this.description,
            active: this.active,
            id: id
          })
            .then(data => {
              this.addToastMessage({
                text: data.message,
                type: "success"
              });
              this.loadZoneevent(id)
                .then(data => {
                  this.name = data.name;
                  this.description = data.description;
                  this.active = data.active;
                })
                .catch(data => {
                  this.addToastMessage({
                    text: "Ocurrio un error",
                    type: "success"
                  });
                });
            })
            .catch(data => {
              this.error = data.message;
              this.errors = data.errors || {};
            });
          //this.$refs.vuetable.refresh();
        }
      });
    },
     addStation() {
      this.$validator.validate().then(result => {
        if (result) {
          var id = this.$route.params.id;
          this.storeZonestation({
            id_station: this.station.id,
            id_zone_event: parseInt(id),
            active: this.active,
          })
            .then(data => {
              this.addToastMessage({
                text: data.message,
                type: "success"
              });

               var idzoneevent = this.$route.params.id;
                this.loadStationsByZoneEvent(idzoneevent)
              .then(data => {
                this.stationsbyzoneevent = data;
              })
              .catch(data => {
                this.addToastMessage({
                  text: "Error consultando usuarios",
                  type: "warning"
                });
              });
               
            })
            .catch(data => {
              this.error = data.message;
              this.errors = data.errors || {};
            });
          //this.$refs.vuetable.refresh();
        }
      });
    },
    deleteStation(id) {
      this.$validator.validate().then(result => {
        if (result) {
          
          this.deleteZonestation({
            
            id: parseInt(id),
          })
            .then(data => {
              this.addToastMessage({
                text: data.message,
                type: "success"
              });
              //setTimeout(() => this.$router.go(-1), 2000);

               var idzoneevent = this.$route.params.id;
                  this.loadStationsByZoneEvent(idzoneevent)
                .then(data => {
                  this.stationsbyzoneevent = data;
                })
                .catch(data => {
                  this.addToastMessage({
                    text: "Error consultando usuarios",
                    type: "warning"
                  });
                });
            })
            .catch(data => {
              this.error = data.message;
              this.errors = data.errors || {};
            });
          //this.$refs.vuetable.refresh();
        }
      });
    },
    trashZoneevent() {
      var id = this.$route.params.id;
     // console.log(id);
      this.deleteZoneevent({

        id: id
      })
        .then(data => {
          this.addToastMessage({
            text: data.message,
            type: "warning"
          });
          setTimeout(() => this.$router.go(-1), 100);
        })
        .catch(data => {
          this.error = data.message;
          this.errors = data.errors || {};
        });
      //this.$refs.vuetable.refresh();
    },
    cleanform() {
      this.name = null;
      this.description = null;
    }
  },
  computed: {

    ...mapState({
      //products: state => state.product.products,
      idevent: state => state.event.event.id,
      idcompany: state => state.auth.company.id
    }),
    isSuccessfulNameValid() {
      let isValid = false;
      if (this.formFields.name) {
        isValid = this.formFields.name.validated && this.formFields.name.valid;
      }
      return isValid;
    },
    isSuccessfulDescriptionValid() {
      let isValid = false;
      if (this.formFields.description) {
        isValid =
          this.formFields.description.validated &&
          this.formFields.description.valid;
      }
      return isValid;
    }
  }
};
</script>
